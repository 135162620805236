export const pagesRoutes = [
  ["v-8daa1a0e","/",{},["/index.html","/index.md"]],
  ["v-0c5302c8","/about/dialtone.html",{},["/about/dialtone","/about/dialtone.md"]],
  ["v-87744040","/components/avatar.html",{},["/components/avatar","/components/avatar.md"]],
  ["v-2a54ea56","/components/badge.html",{},["/components/badge","/components/badge.md"]],
  ["v-32efcded","/components/banner.html",{},["/components/banner","/components/banner.md"]],
  ["v-35f57949","/components/breadcrumbs.html",{},["/components/breadcrumbs","/components/breadcrumbs.md"]],
  ["v-4e5693f5","/components/button-group.html",{},["/components/button-group","/components/button-group.md"]],
  ["v-3bb939a7","/components/button.html",{},["/components/button","/components/button.md"]],
  ["v-40a1d089","/components/card.html",{},["/components/card","/components/card.md"]],
  ["v-00669ac4","/components/checkbox-group.html",{},["/components/checkbox-group","/components/checkbox-group.md"]],
  ["v-20ce47b6","/components/checkbox.html",{},["/components/checkbox","/components/checkbox.md"]],
  ["v-502c1ccd","/components/chip.html",{},["/components/chip","/components/chip.md"]],
  ["v-72b0ce4f","/components/collapsible.html",{},["/components/collapsible","/components/collapsible.md"]],
  ["v-5a97ae3c","/components/combobox.html",{},["/components/combobox","/components/combobox.md"]],
  ["v-23b6895d","/components/datepicker.html",{},["/components/datepicker","/components/datepicker.md"]],
  ["v-63125f2a","/components/description-list.html",{},["/components/description-list","/components/description-list.md"]],
  ["v-639d2028","/components/dropdown.html",{},["/components/dropdown","/components/dropdown.md"]],
  ["v-5a919ac4","/components/emoji-picker.html",{},["/components/emoji-picker","/components/emoji-picker.md"]],
  ["v-f0094882","/components/emoji-text-wrapper.html",{},["/components/emoji-text-wrapper","/components/emoji-text-wrapper.md"]],
  ["v-16997393","/components/emoji.html",{},["/components/emoji","/components/emoji.md"]],
  ["v-46898ab0","/components/empty-state.html",{},["/components/empty-state","/components/empty-state.md"]],
  ["v-43f9e14d","/components/hovercard.html",{},["/components/hovercard","/components/hovercard.md"]],
  ["v-0746ee80","/components/icon.html",{},["/components/icon","/components/icon.md"]],
  ["v-70718e49","/components/illustration.html",{},["/components/illustration","/components/illustration.md"]],
  ["v-4ff21556","/components/image-viewer.html",{},["/components/image-viewer","/components/image-viewer.md"]],
  ["v-1020f180","/components/",{},["/components/index.html","/components/index.md"]],
  ["v-422a8a46","/components/input-group.html",{},["/components/input-group","/components/input-group.md"]],
  ["v-3c8eae8f","/components/input.html",{},["/components/input","/components/input.md"]],
  ["v-a3278116","/components/item-layout.html",{},["/components/item-layout","/components/item-layout.md"]],
  ["v-3bfd87a6","/components/keyboard-shortcut.html",{},["/components/keyboard-shortcut","/components/keyboard-shortcut.md"]],
  ["v-46484823","/components/lazy-show.html",{},["/components/lazy-show","/components/lazy-show.md"]],
  ["v-2327c442","/components/link.html",{},["/components/link","/components/link.md"]],
  ["v-1830f4c5","/components/list-item-group.html",{},["/components/list-item-group","/components/list-item-group.md"]],
  ["v-e43f1312","/components/list-item.html",{},["/components/list-item","/components/list-item.md"]],
  ["v-d8713ce8","/components/modal.html",{},["/components/modal","/components/modal.md"]],
  ["v-7be5a1c1","/components/notice.html",{},["/components/notice","/components/notice.md"]],
  ["v-ba046b82","/components/pagination.html",{},["/components/pagination","/components/pagination.md"]],
  ["v-41a02ed8","/components/popover.html",{},["/components/popover","/components/popover.md"]],
  ["v-ce8c6d04","/components/presence.html",{},["/components/presence","/components/presence.md"]],
  ["v-51e5260c","/components/radio-group.html",{},["/components/radio-group","/components/radio-group.md"]],
  ["v-2c6e2e04","/components/radio.html",{},["/components/radio","/components/radio.md"]],
  ["v-99c32f06","/components/rich-text-editor.html",{},["/components/rich-text-editor","/components/rich-text-editor.md"]],
  ["v-338306c4","/components/root-layout.html",{},["/components/root-layout","/components/root-layout.md"]],
  ["v-14a00c9a","/components/scrollbar.html",{},["/components/scrollbar","/components/scrollbar.md"]],
  ["v-6d69a19f","/components/scroller.html",{},["/components/scroller","/components/scroller.md"]],
  ["v-2857c289","/components/select-menu.html",{},["/components/select-menu","/components/select-menu.md"]],
  ["v-79d2b16c","/components/skeleton.html",{},["/components/skeleton","/components/skeleton.md"]],
  ["v-dd5a0118","/components/split-button.html",{},["/components/split-button","/components/split-button.md"]],
  ["v-634a4a91","/components/stack.html",{},["/components/stack","/components/stack.md"]],
  ["v-df88a5ea","/components/table.html",{},["/components/table","/components/table.md"]],
  ["v-373ba64a","/components/tabs.html",{},["/components/tabs","/components/tabs.md"]],
  ["v-60063b5c","/components/toast.html",{},["/components/toast","/components/toast.md"]],
  ["v-7e1deac5","/components/toggle.html",{},["/components/toggle","/components/toggle.md"]],
  ["v-4d4ca1f6","/components/tooltip.html",{},["/components/tooltip","/components/tooltip.md"]],
  ["v-1545e7f9","/components/validation-messages.html",{},["/components/validation-messages","/components/validation-messages.md"]],
  ["v-72582dd0","/design/",{},["/design/index.html","/design/index.md"]],
  ["v-ff762602","/guides/",{},["/guides/index.html","/guides/index.md"]],
  ["v-25d5a0bc","/tokens/",{},["/tokens/index.html","/tokens/index.md"]],
  ["v-4ae67a1e","/utilities/",{},["/utilities/index.html","/utilities/index.md"]],
  ["v-94e76fb0","/about/whats-new/",{},["/about/whats-new/index.html","/about/whats-new/index.md"]],
  ["v-5311ff3d","/components/status/",{},["/components/status/index.html","/components/status/index.md"]],
  ["v-4bc5dca0","/design/brand/",{},["/design/brand/index.html","/design/brand/index.md"]],
  ["v-5d591ca7","/design/colors/",{},["/design/colors/index.html","/design/colors/index.md"]],
  ["v-a1c3eeec","/design/elevation/",{},["/design/elevation/index.html","/design/elevation/index.md"]],
  ["v-56eac76d","/design/icons/",{},["/design/icons/index.html","/design/icons/index.md"]],
  ["v-e3ff02f8","/design/illustrations/",{},["/design/illustrations/index.html","/design/illustrations/index.md"]],
  ["v-6ec56321","/design/motion/",{},["/design/motion/index.html","/design/motion/index.md"]],
  ["v-4db02636","/design/size/",{},["/design/size/index.html","/design/size/index.md"]],
  ["v-68abe701","/design/space/",{},["/design/space/index.html","/design/space/index.md"]],
  ["v-171828c8","/design/typography/",{},["/design/typography/index.html","/design/typography/index.md"]],
  ["v-1219a940","/guides/accessibility/",{},["/guides/accessibility/index.html","/guides/accessibility/index.md"]],
  ["v-4a2fd172","/guides/brand/",{},["/guides/brand/index.html","/guides/brand/index.md"]],
  ["v-be1a4e56","/guides/content/",{},["/guides/content/index.html","/guides/content/index.md"]],
  ["v-6e7742e6","/guides/contributing/",{},["/guides/contributing/index.html","/guides/contributing/index.md"]],
  ["v-733cc6bc","/guides/design-assets/",{},["/guides/design-assets/index.html","/guides/design-assets/index.md"]],
  ["v-768a8248","/guides/design-principles/",{},["/guides/design-principles/index.html","/guides/design-principles/index.md"]],
  ["v-5519f194","/guides/getting-started/",{},["/guides/getting-started/index.html","/guides/getting-started/index.md"]],
  ["v-253994f2","/tokens/for-designers/",{},["/tokens/for-designers/index.html","/tokens/for-designers/index.md"]],
  ["v-31d597b4","/tokens/for-developers/",{},["/tokens/for-developers/index.html","/tokens/for-developers/index.md"]],
  ["v-895ea8a4","/utilities/backgrounds/attachment.html",{},["/utilities/backgrounds/attachment","/utilities/backgrounds/attachment.md"]],
  ["v-57a627a1","/utilities/backgrounds/clip.html",{},["/utilities/backgrounds/clip","/utilities/backgrounds/clip.md"]],
  ["v-65077b84","/utilities/backgrounds/color.html",{},["/utilities/backgrounds/color","/utilities/backgrounds/color.md"]],
  ["v-b02c8b44","/utilities/backgrounds/gradients.html",{},["/utilities/backgrounds/gradients","/utilities/backgrounds/gradients.md"]],
  ["v-15968824","/utilities/backgrounds/patterns.html",{},["/utilities/backgrounds/patterns","/utilities/backgrounds/patterns.md"]],
  ["v-fb55b530","/utilities/backgrounds/position.html",{},["/utilities/backgrounds/position","/utilities/backgrounds/position.md"]],
  ["v-43ccae36","/utilities/backgrounds/repeat.html",{},["/utilities/backgrounds/repeat","/utilities/backgrounds/repeat.md"]],
  ["v-56639ce0","/utilities/backgrounds/size.html",{},["/utilities/backgrounds/size","/utilities/backgrounds/size.md"]],
  ["v-17e8533c","/utilities/borders/color.html",{},["/utilities/borders/color","/utilities/borders/color.md"]],
  ["v-8dbc6cc0","/utilities/borders/direction.html",{},["/utilities/borders/direction","/utilities/borders/direction.md"]],
  ["v-3c11a684","/utilities/borders/divide-color.html",{},["/utilities/borders/divide-color","/utilities/borders/divide-color.md"]],
  ["v-decda3fe","/utilities/borders/divide-width.html",{},["/utilities/borders/divide-width","/utilities/borders/divide-width.md"]],
  ["v-42544061","/utilities/borders/radius.html",{},["/utilities/borders/radius","/utilities/borders/radius.md"]],
  ["v-5939b4a4","/utilities/borders/style.html",{},["/utilities/borders/style","/utilities/borders/style.md"]],
  ["v-6c6fdab9","/utilities/borders/width.html",{},["/utilities/borders/width","/utilities/borders/width.md"]],
  ["v-858ab068","/utilities/effects/box-shadow.html",{},["/utilities/effects/box-shadow","/utilities/effects/box-shadow.md"]],
  ["v-a50c7a8e","/utilities/effects/opacity.html",{},["/utilities/effects/opacity","/utilities/effects/opacity.md"]],
  ["v-27fed5b9","/utilities/effects/transition.html",{},["/utilities/effects/transition","/utilities/effects/transition.md"]],
  ["v-0f87e7c0","/utilities/flex/align-content.html",{},["/utilities/flex/align-content","/utilities/flex/align-content.md"]],
  ["v-147c18d9","/utilities/flex/align-items.html",{},["/utilities/flex/align-items","/utilities/flex/align-items.md"]],
  ["v-54897d66","/utilities/flex/align-self.html",{},["/utilities/flex/align-self","/utilities/flex/align-self.md"]],
  ["v-470b7bd8","/utilities/flex/columns-layouts.html",{},["/utilities/flex/columns-layouts","/utilities/flex/columns-layouts.md"]],
  ["v-00d47aa4","/utilities/flex/direction-wrap-flow.html",{},["/utilities/flex/direction-wrap-flow","/utilities/flex/direction-wrap-flow.md"]],
  ["v-1f6d67dc","/utilities/flex/flex-grow-shrink.html",{},["/utilities/flex/flex-grow-shrink","/utilities/flex/flex-grow-shrink.md"]],
  ["v-c40d908a","/utilities/flex/gap.html",{},["/utilities/flex/gap","/utilities/flex/gap.md"]],
  ["v-803c377e","/utilities/flex/justify.html",{},["/utilities/flex/justify","/utilities/flex/justify.md"]],
  ["v-0b7dac43","/utilities/flex/order.html",{},["/utilities/flex/order","/utilities/flex/order.md"]],
  ["v-196d2e74","/utilities/grid/column-start-end-span.html",{},["/utilities/grid/column-start-end-span","/utilities/grid/column-start-end-span.md"]],
  ["v-0f07d9e4","/utilities/grid/gap.html",{},["/utilities/grid/gap","/utilities/grid/gap.md"]],
  ["v-94b1bbfe","/utilities/grid/justify-items.html",{},["/utilities/grid/justify-items","/utilities/grid/justify-items.md"]],
  ["v-081319b6","/utilities/grid/justify-self.html",{},["/utilities/grid/justify-self","/utilities/grid/justify-self.md"]],
  ["v-16e0f1bb","/utilities/grid/layouts.html",{},["/utilities/grid/layouts","/utilities/grid/layouts.md"]],
  ["v-8b42eede","/utilities/grid/place-content.html",{},["/utilities/grid/place-content","/utilities/grid/place-content.md"]],
  ["v-d9128c2c","/utilities/grid/place-items.html",{},["/utilities/grid/place-items","/utilities/grid/place-items.md"]],
  ["v-6d608bc8","/utilities/grid/place-self.html",{},["/utilities/grid/place-self","/utilities/grid/place-self.md"]],
  ["v-7c54e7d8","/utilities/grid/row-start-end-span.html",{},["/utilities/grid/row-start-end-span","/utilities/grid/row-start-end-span.md"]],
  ["v-432fcc80","/utilities/grid/understanding.html",{},["/utilities/grid/understanding","/utilities/grid/understanding.md"]],
  ["v-bc86327e","/utilities/interactivity/cursor.html",{},["/utilities/interactivity/cursor","/utilities/interactivity/cursor.md"]],
  ["v-36a923ce","/utilities/interactivity/outline.html",{},["/utilities/interactivity/outline","/utilities/interactivity/outline.md"]],
  ["v-10b4b94e","/utilities/interactivity/pointer-events.html",{},["/utilities/interactivity/pointer-events","/utilities/interactivity/pointer-events.md"]],
  ["v-688aefba","/utilities/interactivity/resize.html",{},["/utilities/interactivity/resize","/utilities/interactivity/resize.md"]],
  ["v-0e7628d2","/utilities/layout/box-sizing.html",{},["/utilities/layout/box-sizing","/utilities/layout/box-sizing.md"]],
  ["v-2d2b5fb6","/utilities/layout/coordinates.html",{},["/utilities/layout/coordinates","/utilities/layout/coordinates.md"]],
  ["v-138fe13e","/utilities/layout/display.html",{},["/utilities/layout/display","/utilities/layout/display.md"]],
  ["v-2b932b10","/utilities/layout/overflow.html",{},["/utilities/layout/overflow","/utilities/layout/overflow.md"]],
  ["v-46e66649","/utilities/layout/position.html",{},["/utilities/layout/position","/utilities/layout/position.md"]],
  ["v-4aaa0bc0","/utilities/layout/visibility.html",{},["/utilities/layout/visibility","/utilities/layout/visibility.md"]],
  ["v-92a4d33e","/utilities/layout/z-index.html",{},["/utilities/layout/z-index","/utilities/layout/z-index.md"]],
  ["v-24418cd8","/utilities/responsive/breakpoints.html",{},["/utilities/responsive/breakpoints","/utilities/responsive/breakpoints.md"]],
  ["v-55e762c2","/utilities/sizing/height.html",{},["/utilities/sizing/height","/utilities/sizing/height.md"]],
  ["v-39bb0136","/utilities/sizing/max-height.html",{},["/utilities/sizing/max-height","/utilities/sizing/max-height.md"]],
  ["v-47c7592f","/utilities/sizing/max-width.html",{},["/utilities/sizing/max-width","/utilities/sizing/max-width.md"]],
  ["v-10e9e5b8","/utilities/sizing/min-height.html",{},["/utilities/sizing/min-height","/utilities/sizing/min-height.md"]],
  ["v-6eef1781","/utilities/sizing/min-width.html",{},["/utilities/sizing/min-width","/utilities/sizing/min-width.md"]],
  ["v-6dd23aa6","/utilities/sizing/width.html",{},["/utilities/sizing/width","/utilities/sizing/width.md"]],
  ["v-7e004aaa","/utilities/spacing/auto-spacing.html",{},["/utilities/spacing/auto-spacing","/utilities/spacing/auto-spacing.md"]],
  ["v-04607141","/utilities/spacing/margin.html",{},["/utilities/spacing/margin","/utilities/spacing/margin.md"]],
  ["v-38028e5c","/utilities/spacing/padding.html",{},["/utilities/spacing/padding","/utilities/spacing/padding.md"]],
  ["v-0b89f95c","/utilities/typography/font-color.html",{},["/utilities/typography/font-color","/utilities/typography/font-color.md"]],
  ["v-142c1779","/utilities/typography/font-family.html",{},["/utilities/typography/font-family","/utilities/typography/font-family.md"]],
  ["v-7b68fdfc","/utilities/typography/font-size.html",{},["/utilities/typography/font-size","/utilities/typography/font-size.md"]],
  ["v-94945478","/utilities/typography/font-style.html",{},["/utilities/typography/font-style","/utilities/typography/font-style.md"]],
  ["v-078ddb30","/utilities/typography/font-variant-numeric.html",{},["/utilities/typography/font-variant-numeric","/utilities/typography/font-variant-numeric.md"]],
  ["v-68e70636","/utilities/typography/font-weight.html",{},["/utilities/typography/font-weight","/utilities/typography/font-weight.md"]],
  ["v-46bf57bb","/utilities/typography/line-height.html",{},["/utilities/typography/line-height","/utilities/typography/line-height.md"]],
  ["v-71340fe6","/utilities/typography/lists.html",{},["/utilities/typography/lists","/utilities/typography/lists.md"]],
  ["v-0f3857f5","/utilities/typography/styles.html",{},["/utilities/typography/styles","/utilities/typography/styles.md"]],
  ["v-50b37d5c","/utilities/typography/text-align.html",{},["/utilities/typography/text-align","/utilities/typography/text-align.md"]],
  ["v-2e9ef6ea","/utilities/typography/text-decoration.html",{},["/utilities/typography/text-decoration","/utilities/typography/text-decoration.md"]],
  ["v-488816e8","/utilities/typography/text-opacity.html",{},["/utilities/typography/text-opacity","/utilities/typography/text-opacity.md"]],
  ["v-4b5a060e","/utilities/typography/text-overflow.html",{},["/utilities/typography/text-overflow","/utilities/typography/text-overflow.md"]],
  ["v-0a30936a","/utilities/typography/text-transform.html",{},["/utilities/typography/text-transform","/utilities/typography/text-transform.md"]],
  ["v-0acaf869","/utilities/typography/vertical-align.html",{},["/utilities/typography/vertical-align","/utilities/typography/vertical-align.md"]],
  ["v-7f69543a","/utilities/typography/whitespace.html",{},["/utilities/typography/whitespace","/utilities/typography/whitespace.md"]],
  ["v-31914d9b","/utilities/typography/word-break.html",{},["/utilities/typography/word-break","/utilities/typography/word-break.md"]],
  ["v-2be4a04e","/utilities/typography/word-wrap.html",{},["/utilities/typography/word-wrap","/utilities/typography/word-wrap.md"]],
  ["v-3a2c6330","/about/whats-new/posts/2022-11-18.html",{},["/about/whats-new/posts/2022-11-18","/about/whats-new/posts/2022-11-18.md"]],
  ["v-5e2fc541","/about/whats-new/posts/2022-12-9.html",{},["/about/whats-new/posts/2022-12-9","/about/whats-new/posts/2022-12-9.md"]],
  ["v-09bf4b18","/about/whats-new/posts/2023-10-30.html",{},["/about/whats-new/posts/2023-10-30","/about/whats-new/posts/2023-10-30.md"]],
  ["v-0b08b2d1","/about/whats-new/posts/2023-12-28.html",{},["/about/whats-new/posts/2023-12-28","/about/whats-new/posts/2023-12-28.md"]],
  ["v-67453fa7","/about/whats-new/posts/2023-2-7.html",{},["/about/whats-new/posts/2023-2-7","/about/whats-new/posts/2023-2-7.md"]],
  ["v-cc6b8732","/about/whats-new/posts/2023-5-4.html",{},["/about/whats-new/posts/2023-5-4","/about/whats-new/posts/2023-5-4.md"]],
  ["v-608e2b36","/about/whats-new/posts/2023-8-3.html",{},["/about/whats-new/posts/2023-8-3","/about/whats-new/posts/2023-8-3.md"]],
  ["v-a40c856a","/about/whats-new/posts/2024-10-3.html",{},["/about/whats-new/posts/2024-10-3","/about/whats-new/posts/2024-10-3.md"]],
  ["v-79575f52","/about/whats-new/posts/2024-11-25.html",{},["/about/whats-new/posts/2024-11-25","/about/whats-new/posts/2024-11-25.md"]],
  ["v-5fa55012","/about/whats-new/posts/2024-12-10.html",{},["/about/whats-new/posts/2024-12-10","/about/whats-new/posts/2024-12-10.md"]],
  ["v-bb8099d0","/about/whats-new/posts/2024-3-20.html",{},["/about/whats-new/posts/2024-3-20","/about/whats-new/posts/2024-3-20.md"]],
  ["v-0a2621f3","/about/whats-new/posts/2024-4-15.html",{},["/about/whats-new/posts/2024-4-15","/about/whats-new/posts/2024-4-15.md"]],
  ["v-c32ade18","/about/whats-new/posts/2024-5-15.html",{},["/about/whats-new/posts/2024-5-15","/about/whats-new/posts/2024-5-15.md"]],
  ["v-64a969c0","/about/whats-new/posts/2024-7-8.html",{},["/about/whats-new/posts/2024-7-8","/about/whats-new/posts/2024-7-8.md"]],
  ["v-7eceab74","/about/whats-new/posts/2024-8-1.html",{},["/about/whats-new/posts/2024-8-1","/about/whats-new/posts/2024-8-1.md"]],
  ["v-57ce2cb9","/about/whats-new/posts/2024-8-13.html",{},["/about/whats-new/posts/2024-8-13","/about/whats-new/posts/2024-8-13.md"]],
  ["v-24ae0856","/guides/content/action-language/",{},["/guides/content/action-language/index.html","/guides/content/action-language/index.md"]],
  ["v-5bffb569","/guides/content/error-messages/",{},["/guides/content/error-messages/index.html","/guides/content/error-messages/index.md"]],
  ["v-8536ff5e","/guides/content/grammar-and-mechanics/",{},["/guides/content/grammar-and-mechanics/index.html","/guides/content/grammar-and-mechanics/index.md"]],
  ["v-d5ccdda6","/guides/content/help-content/",{},["/guides/content/help-content/index.html","/guides/content/help-content/index.md"]],
  ["v-6a4d8241","/guides/content/inclusive-language/",{},["/guides/content/inclusive-language/index.html","/guides/content/inclusive-language/index.md"]],
  ["v-f97ef592","/guides/content/voice-and-tone/",{},["/guides/content/voice-and-tone/index.html","/guides/content/voice-and-tone/index.md"]],
  ["v-3706649a","/404.html",{},["/404"]],
]
