function i(t, e = document.documentElement) {
  c(t.base.name, t.brand.name, e), e != null && e.shadowRoot && (e = e.shadowRoot), a("dialtone-css-theme", t.base.css, e), a("dialtone-css-brand", t.brand.css, e);
}
function a(t, e, s) {
  if (s.querySelector("#" + t))
    s.querySelector("#" + t).innerHTML = e;
  else {
    const n = document.createElement("style");
    n.setAttribute("type", "text/css"), n.setAttribute("id", t), n.innerHTML = e, s.querySelector("head") ? s.querySelector("head").appendChild(n) : s.appendChild(n);
  }
}
function c(t, e, s) {
  s.setAttribute("data-dt-theme", t), s.setAttribute("data-dt-brand", e);
}
export {
  i as setTheme
};
