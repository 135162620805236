import clientConfig0 from '/home/runner/work/dialtone/dialtone/node_modules/.pnpm/@vuepress+plugin-theme-data@2.0.0-beta.60_typescript@5.6.3/node_modules/@vuepress/plugin-theme-data/lib/client/config.js'
import clientConfig1 from '/home/runner/work/dialtone/dialtone/node_modules/.pnpm/@vuepress+plugin-active-header-links@2.0.0-beta.60_typescript@5.6.3/node_modules/@vuepress/plugin-active-header-links/lib/client/config.js'
import clientConfig2 from '/home/runner/work/dialtone/dialtone/node_modules/.pnpm/@vuepress+plugin-back-to-top@2.0.0-beta.60_typescript@5.6.3/node_modules/@vuepress/plugin-back-to-top/lib/client/config.js'
import clientConfig3 from '/home/runner/work/dialtone/dialtone/node_modules/.pnpm/@vuepress+plugin-docsearch@2.0.0-beta.60_@algolia+client-search@4.20.0_@types+react@18.2.37_r_fgjq5qfoibcng36lkwuegxi7ee/node_modules/@vuepress/plugin-docsearch/lib/client/config.js'
import clientConfig4 from '/home/runner/work/dialtone/dialtone/apps/dialtone-documentation/docs/.vuepress/theme/client.js'
import clientConfig5 from '/home/runner/work/dialtone/dialtone/node_modules/.pnpm/@vuepress+plugin-google-analytics@2.0.0-beta.60_typescript@5.6.3/node_modules/@vuepress/plugin-google-analytics/lib/client/config.js'
import clientConfig6 from '/home/runner/work/dialtone/dialtone/apps/dialtone-documentation/docs/.vuepress/client.js'

export const clientConfigs = [
  clientConfig0,
  clientConfig1,
  clientConfig2,
  clientConfig3,
  clientConfig4,
  clientConfig5,
  clientConfig6,
]
