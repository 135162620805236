<template>
  <router-link
    class="d-pl8 d-w216 d-fc-secondary d-td-none h:d-fc-primary"
    title="Go back to the homepage"
    to="/"
  >
    <dt-stack gap="400" direction="row">
      <dt-icon
        name="dialpad-ai-color-reversed"
        size="500"
      />
      <span class="d-headline--lg">Dialtone</span>
    </dt-stack>
  </router-link>
</template>
